<template>
  <draggable
    class="list-group"
    tag="ul"
    v-model="computedData"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
      <li
        class="list-group-item hover-background-indigo"
        v-bind:class="[
          index == computedData.length - 1 ? 'rounded-bottom' : '',
          index == 0 ? 'rounded-top' : '',
        ]"
        v-for="(element, index) in computedData"
        :key="element.key"
      >
        <i class="fas fa-grip-vertical mr-2 text-muted"></i>
        {{ element.value }}
      </li>
    </transition-group>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "SelectedItems",
  props: {
    data: {
      default: () => [],
    },
  },
  components: {
    draggable,
  },
  model: {
    prop: "data",
  },
  data() {
    return {
      drag: false,
    };
  },
  computed: {
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>
<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
</style>